
import { Box, Divider, TablePagination } from "@mui/material";
import theme from "core/theme/theme";
import {Dispatch, SetStateAction} from "react";

export interface IPropsPagination {
  setPage : Dispatch<SetStateAction<number>>;
  page: number;
  setRowsPerPage : Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  count: number;
  
}

export default function DataTablePagination(props: IPropsPagination) {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };
  return (
    <Box>
      <Divider />
      <TablePagination
        sx={{ overflow: "hidden", backgroundColor: theme.COLORS.WHITE}}
        component="div"
        count={props.count}
        page={props.page}
        onPageChange={handleChangePage}
        rowsPerPage={props.rowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
        labelRowsPerPage={"Linhas por pagina"}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showLastButton={true}
        showFirstButton={true}

      />
    </Box>
  );
}
