import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Tooltip, IconButton } from "@mui/material";
import { FormatBold, FormatItalic, FormatUnderlined } from "@mui/icons-material";

// Definindo as propriedades do componente
interface CustomToolbarProps {
    isSmallScreen: boolean;
}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({ isSmallScreen }) => (
    <div
        id="toolbar"
        style={{
            width: isSmallScreen ? "100%" : "60%", 
        }}
    >
        <Tooltip title="Tamanho da fonte">
            <select className="ql-size">
                <option value="small">Pequena</option>
                <option selected>Normal</option>
                <option value="large">Grande</option>
            </select>
        </Tooltip>
        <Tooltip title="Negrito">
            <IconButton className="ql-bold">
                <FormatBold />
            </IconButton>
        </Tooltip>
        <Tooltip title="Itálico">
            <IconButton className="ql-italic">
                <FormatItalic />
            </IconButton>
        </Tooltip>
        <Tooltip title="Lista ordenada">
            <button className="ql-list" value="ordered" />
        </Tooltip>
        <Tooltip title="Lista não ordenada">
            <button className="ql-list" value="bullet" />
        </Tooltip>
        <Tooltip title="Link">
            <button className="ql-link" />
        </Tooltip>
        <Tooltip title="Limpar formatação">
            <button className="ql-clean" />
        </Tooltip>
    </div>
);
