import { Notification } from "app/components/toastNotification/toastNotification";
import { IResponseBody } from "core/models/utils";
import { apiService } from "../axios/axiosInstance";
import { GET_PROJECTS } from "core/utils/constants";

class ProjectService {


    // get de todos os projetos cadastrados
    async getProject(): Promise<IResponseBody<any>> {
        return await this.apiCall(() => apiService.get(`${GET_PROJECTS}`),
            "Projetos recuperados com sucesso",
            false);
    }


    // Método privado para centralizar o tratamento de chamadas API
    private async apiCall(
        call: () => Promise<any>,
        successMessage?: string,
        showNotification: boolean = true
    ): Promise<any> {
        try {
            const response = await call();
            if (successMessage && showNotification) {
                Notification(successMessage, "success");
            }
            return response;
        } catch (err) {
            console.error(err);
            Notification(String(err), "error");
            throw err;
        }
    }
}

export const projectService = new ProjectService();