import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import theme from "core/theme/theme";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Annex,
  AnnexButton,
  AutoCompletes,
  CircularIcon,
  CreateCall,
  Description,
  DropAnnex,
  FormInfos,
  Images,
  PageContent,
  ReletorContent,
  ReletorName,
  TopContent,
} from "./styles";
import { CustomToolbar } from "app/components/customToolbar/customToolbar";
import { getFirstLetter } from "core/utils/globalFunctions";
import { projectService } from "core/api/project/projectService";
import { useNavigate } from "react-router-dom";
import TaskService from "core/api/task/taskService";
import { Validations } from "core/utils/validations";

const NewRequest = () => {
  const [levelCriticalityyOptions, setLevelCriticalityyOptions] = useState("");
  const [selectedProject, setSelectedProject] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadOptions, setLoadOptions] = useState(false);
  const [projectOptions, setProjectOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const user = localStorage.getItem("user");
  const userObj = JSON.parse(user!!);
  const userId = userObj.id;
  const userName = userObj.name;

  const initialValues: any = {
    description: "",
    requesterId: userId,
    projectId: 0,
    levelCriticality: "",
    title: "",
    dataSaveImage: [],
  };

  useEffect(() => {
    const fetchProjects = async () => {
      if (loadOptions) return;
      setIsLoading(true);
      const response = await projectService.getProject();
      setIsLoading(false);
      setLoadOptions(true);
      setProjectOptions(response.data.content);
      return response.data;
    };
    fetchProjects();
  }, []);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Validations.registerTaskSchema,
    initialValues,
    onSubmit: async (values) => {
      setIsLoading(true);
      await TaskService.saveTask(values)
        .then((response) => {
          setIsLoading(false);
          navigate("/chamados");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
  });

  const handleChangePriority = (event: any) => {
    setLevelCriticalityyOptions(event.target.value as string);
    formik.setFieldValue("levelCriticality", event.target.value as string);
  };

  // state para guardar os arquivos selecionados
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // converte imagem para base64
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).replace(
          /^data:image\/(png|jpeg);base64,/,
          ""
        ); // Remove the prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // função para lidar com o upload de arquivos
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // converte os arquivos para base64 e atualiza o Formik
      const base64Images = await Promise.all(
        newFiles.map((file) => convertToBase64(file))
      );
      formik.setFieldValue("dataSaveImage", [
        ...formik.values.dataSaveImage,
        ...base64Images.map((base64) => ({
          typeImage: "PNG",
          imageBase64: base64,
        })),
      ]);
    }
  };

  // função para lidar com o drop de arquivos
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const newFiles = Array.from(files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // converte os arquivos para base64 e atualiza o Formik
      const base64Images = await Promise.all(
        newFiles.map((file) => convertToBase64(file))
      );
      formik.setFieldValue("dataSaveImage", [
        ...formik.values.dataSaveImage,
        ...base64Images.map((base64) => ({
          typeImage: "PNG",
          imageBase64: base64,
        })),
      ]);
    }
  };

  // Remove image
  const removeImage = (indexToRemove: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    formik.setFieldValue(
      "dataSaveImage",
      formik.values.dataSaveImage.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const formatProjectName = (project: string) => {
    switch (project) {
      case "LIMPANOME":
        return "Limpa Nome";
      case "joao-pessoa":
        return "LBS";
      case "POSICARD":
        return "Posicard";
      default:
        return project;
    }
  };

  return (
    <PageContent $small={isSmallScreen}>
      <TopContent $small={isSmallScreen}>
        <Box>
          <Typography
            sx={{ fontSize: 32, fontWeight: 1000, color: theme.COLORS.GRAY3 }}
          >
            Novo Chamado
          </Typography>
        </Box>

        {!isSmallScreen && (
          <ReletorContent>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                color: theme.COLORS.GRAY2,
              }}
            >
              Relator
            </Typography>
            <ReletorName>
              <CircularIcon>
                <Typography
                  sx={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: theme.COLORS.WHITE,
                    textAlign: "center",
                  }}
                >
                  {userName ? getFirstLetter(userName) : "--"}
                </Typography>
              </CircularIcon>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: "bold",
                  color: theme.COLORS.GRAY2,
                }}
              >
                {userName || "--"}
              </Typography>
            </ReletorName>
          </ReletorContent>
        )}
      </TopContent>

      <FormInfos $small={isSmallScreen}>
        <TextField
          value={formik.values.title}
          onChange={formik.handleChange}
          variant="standard"
          error={!!formik.errors.title}
          helperText={
            !!formik.errors.title && (
              <FormHelperText sx={{ margin: -0.5, padding: 0 }} error>
                {String(formik.errors.title)}
              </FormHelperText>
            )
          }
          label="Titulo do chamado"
          name="title"
          sx={{
            width: isSmallScreen ? "90%" : "50%",
            "& .MuiInputLabel-root": {
              fontSize: 24,
            },
          }}
          InputProps={{
            sx: {
              fontSize: 22,
            },
          }}
        />
        <Description>
          <Typography
            sx={{ fontSize: 15, fontWeight: "bold", color: theme.COLORS.GRAY2 }}
          >
            Descrição
          </Typography>
          <CustomToolbar isSmallScreen={isSmallScreen} />
          <ReactQuill
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
            style={{
              width: isSmallScreen ? "100%" : "60%",
              height: isSmallScreen ? "130px" : "200px",
              maxHeight: isSmallScreen ? "130px" : "200px",
            }}
            modules={{
              toolbar: {
                container: "#toolbar",
              },
            }}
          />
          {formik.errors.description && (
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {String(formik.errors.description)}
            </Typography>
          )}
        </Description>

        <Annex>
          <Typography
            sx={{ fontSize: 15, fontWeight: "bold", color: theme.COLORS.GRAY2 }}
          >
            Anexos
          </Typography>
          <DropAnnex
            $small={isSmallScreen}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <Typography
              sx={{ fontSize: 13, fontWeight: "bold", color: "gray" }}
            >
              Escolha ou arraste e solte o arquivo para anexar
            </Typography>
            <AnnexButton component="label">
              Escolher
              <input
                type="file"
                hidden
                multiple
                accept="image/png"
                onChange={handleFileChange}
              />
            </AnnexButton>

            {/* Display the selected images */}
            <Images>
              {selectedFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Avatar
                    alt="Imagem"
                    src={URL.createObjectURL(file)}
                    sx={{ width: 40, height: 40, cursor: "default" }}
                  />
                  <Typography sx={{ fontSize: 12, color: "gray" }}>
                    {file.name}
                  </Typography>
                  <IconButton onClick={() => removeImage(index)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              ))}
            </Images>
          </DropAnnex>
          {formik.errors.dataSaveImage && (
            <Typography sx={{ color: "red", fontSize: 12 }}>
              {String(formik.errors.dataSaveImage)}
            </Typography>
          )}
        </Annex>

        <AutoCompletes $small={isSmallScreen}>
          <FormControl fullWidth>
            <TextField
              select
              label="Projeto"
              value={selectedProject}
              onChange={(e) => {
                const newValue: any = e.target.value;
                formik.setFieldValue("projectId", newValue.id);
                setSelectedProject(formatProjectName(newValue.name));
              }}
              size="small"
              sx={{
                width: "100%",
              }}
              error={!!formik.errors.projectId}
              helperText={
                !!formik.errors.projectId && (
                  <FormHelperText sx={{ margin: -0.5, padding: 0 }} error>
                    {String(formik.errors.projectId)}
                  </FormHelperText>
                )
              }
            >
              {isLoading ? (
                <MenuItem
                  disabled
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="inherit" size={20} />
                </MenuItem>
              ) : (
                projectOptions.map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {formatProjectName(String(option.name))}
                  </MenuItem>
                ))
              )}
            </TextField>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              select
              label="Nível de criticidade"
              value={levelCriticalityyOptions}
              error={!!formik.errors.title}
              helperText={
                !!formik.errors.levelCriticality && (
                  <FormHelperText sx={{ margin: -0.5, padding: 0 }} error>
                    {String(formik.errors.levelCriticality)}
                  </FormHelperText>
                )
              }
              onChange={handleChangePriority}
              size="small"
              sx={{
                width: "100%",
              }}
            >
              <MenuItem value={"BAIXA"}>Baixa</MenuItem>
              <MenuItem value={"MEDIA"}>Média</MenuItem>
              <MenuItem value={"ALTA"}>Alta</MenuItem>
              <MenuItem value={"CRITICA"}>Crítica</MenuItem>
            </TextField>
          </FormControl>
        </AutoCompletes>
        <CreateCall>
          <Button
            sx={{ width: isSmallScreen ? "60%" : "20%" }}
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> :
              <Typography>CRIAR CHAMADO</Typography>}
          </Button>
        </CreateCall>
      </FormInfos>
    </PageContent>
  );
};

export default NewRequest;
