import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import theme from "core/theme/theme";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import { useCallback, useEffect, useState } from "react";
import NavigationIcon from "@mui/icons-material/Navigation";
import DoneIcon from '@mui/icons-material/Done';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import BackHandIcon from '@mui/icons-material/BackHand';
import StyledStatus from "app/components/styledStatus/styledStatus";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  AvatarBox,
  CancelButton,
  ChatContainer,
  CommentBox,
  CommentContainer,
  CommentContent,
  CommentQuantity,
  CommentText,
  Container,
  DataComment,
  DescriptionContainer,
  DescriptionContent,
  DescriptionText,
  DescriptionTitle,
  ExecutorContainer,
  HistoryContainer,
  HistoryContent,
  IconContainer,
  IconLetter,
  ImageContainer,
  InfoBox,
  InfoContainer,
  InfoRow,
  InfoText,
  InputContainer,
  LeftContainer,
  NameUser,
  NameUserChat,
  PersonIconStyled,
  Quantity,
  ReletorAndExecutor,
  ReletorContainer,
  RequestTitle,
  RightContainer,
  UserBox,
} from "./styles";
import { useParams } from "react-router-dom";
import { formatDateBr, formatDateWithTime, getFirstLetter } from "core/utils/globalFunctions";
import TaskService from "core/api/task/taskService";
import CommentService from "core/api/comment/commentService";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "core/hooks/reduxHooks";
import DefaultChat from "app/components/chat/defaultChat";
import History from "app/components/defaultHistoric/defaultHistoric";

const Request = () => {
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const userRoles = basicUserInfo?.roles as any;
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const [isLoadingTask, setIsLoadingTask] = useState(false);
  const [request, setRequest] = useState<any>();
  const [historic, setHistoric] = useState<any>();
  const [postingComment, setPostingComment] = useState(false);
  const user = localStorage.getItem("user");
  const userObj = JSON.parse(user!!);
  const userId = userObj.id;
  const [isUpdating, setIsUpdating] = useState(false)
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const [selectedImage, setSelectedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [open, setOpen] = useState(false);

  const handleRotateRight = () => {
    setRotation(prev => prev + 90);
  };

  const handleRotateLeft = () => {
    setRotation(prev => prev - 90);
  };

  const handleResetRotation = () => {
    setRotation(0);
  };

  const handleOpen = (image: any) => {
    setSelectedImage(image);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [imageResponses, setImageResponses] = useState<any[]>([]); // state para armazenar as respostas das imagens

  // Função combinada que busca os detalhes da task e após isso busca as imagens
  const fetchRequestAndImages = useCallback(async () => {
    if (!id) return;
    setIsLoadingTask(true);

    try {
      // Fetch da tarefa pelo id
      const response = await TaskService.getTaskById(Number(id));
      const historic = await TaskService.getHistory(Number(id));
      setRequest(response.data);
      setHistoric(historic.data);

      // Fetch das imagens associadas
      const imageList = response.data.imageList || [];
      const imageResponses = await Promise.all(
        imageList.map(async (image: any) => {
          const imageResponse = await TaskService.getImage(Number(image.id));
          return imageResponse.data;
        })
      );
      setImageResponses(imageResponses);
    } catch (error) {
      console.error("Error fetching task or images", error);
    } finally {
      setIsLoadingTask(false);
    }
  }, [id]);

  useEffect(() => {
    fetchRequestAndImages();
  }, [fetchRequestAndImages]);


  // ? get dos comentarios
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["comments", id],
    queryFn: () => CommentService.getComments(Number(id)),
    enabled: !!id,
  });


  // ? atualiza o status da task pra fechada (ação feita pelo requester)
  const handleCloseTask = async () => {
    setIsUpdating(true)
    await TaskService.closeTask(Number(id)).then((resp) => {
      setIsUpdating(false)
      fetchRequestAndImages()
    });
  };

  // ? atualiza o status da task pra finalizada (ação feita pelo executor)
  const handleFinishTask = async () => {
    setIsUpdating(true)
    await TaskService.finishTask(Number(id)).then((resp) => {
      setIsUpdating(false)
      fetchRequestAndImages()
    });
  };


  // ? função para baixar a imagem
  const downloadImage = (image: string | undefined) => {
    if (image) {
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64,${image}`;
      link.download = 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // envia o comentário
  const handlePostComment = async (comment: any) => {
    const trimmedComment = comment.trim();
    // verifica se o comentário não é vazio
    if (trimmedComment !== "" && id !== undefined && userRoles === "[ROLE_ADMIN]") {
      const newComment: any = {
        comente: trimmedComment,
        taskId: Number(id),
        executorId: userId,
        type: "nao entendi",
      }
      setPostingComment(true);
      await CommentService.postExecutorComment(newComment);
      setComment("");
      setPostingComment(false);
      refetch();
    }
    else if (trimmedComment !== "" && id !== undefined && userRoles !== "[ROLE_ADMIN]") {
      const newComment: any = {
        comente: trimmedComment,
        taskId: Number(id),
        requesterId: userId,
        type: "nao entendi",
      }
      setPostingComment(true);
      await CommentService.postRequesterComment(newComment);
      setComment("");
      setPostingComment(false);
      refetch();
    }
  };

  const handleAssignTask = useCallback(async () => {
    if (userRoles === "[ROLE_ADMIN]" && userId && id) {
      await TaskService.assignTask(userId, Number(id)).then((resp) => {
        refetch();
      });
    }
  }, [id, userRoles, userId]);

  return (
    <Container $small={isSmallScreen}>
      {isLoading || isLoadingTask ? (
        <Box
          sx={{
            width: "100%",
            height: "60svh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <LeftContainer>
            <RequestTitle> {request?.title} </RequestTitle>

            <DescriptionContainer>
              <DescriptionTitle> Descrição </DescriptionTitle>
              <DescriptionContent>
                <DescriptionText
                  dangerouslySetInnerHTML={{
                    __html: request?.description,
                  }}
                />
              </DescriptionContent>
            </DescriptionContainer>

            <Divider sx={{ width: "95%" }} />

            <DefaultChat
              comments={data?.data}
              comment={comment}
              postingComment={postingComment}
              userRoles={userRoles}
              isSmallScreen={isSmallScreen}
              setComment={setComment}
              handlePostComment={handlePostComment}
              formatDateWithTime={formatDateWithTime}
            />


            <ImageContainer>
              <Divider sx={{ width: "95%" }} />
              <Typography sx={{ fontWeight: "bold", color: theme.COLORS.GRAY2 }} >
                Anexos
              </Typography>
              <ImageList sx={{ width: "90%", height: 225 }} cols={3} rowHeight={225}>
                {imageResponses.map((imageBase64, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`data:image/png;base64,${imageBase64}`}
                      alt={`Image ${index}`}
                      loading="lazy"
                      style={{
                        cursor: "pointer", borderRadius: "5px"
                        , border: "1px solid", borderColor: theme.COLORS.DARKBLUE
                      }}
                      onClick={() => handleOpen(`data:image/jpeg;base64,${imageBase64}`)}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </ImageContainer>
            {userRoles !== "[ROLE_ADMIN]" &&
              <CancelButton>
                Cancelar chamado
              </CancelButton>}
          </LeftContainer>

          <RightContainer $small={isSmallScreen}>

            <ReletorAndExecutor $small={isSmallScreen}>
              <ReletorContainer $small={isSmallScreen}>
                <NameUser> Relator </NameUser>
                <InfoBox>
                  <AvatarBox>
                    <IconLetter>
                      {" "}
                      {request?.nameRequester
                        ? getFirstLetter(request?.nameRequester)
                        : "--"}{" "}
                    </IconLetter>
                  </AvatarBox>
                  <NameUser> {request?.nameRequester} </NameUser>
                </InfoBox>
              </ReletorContainer>

              <ExecutorContainer $small={isSmallScreen}>

                <NameUser> Reponsável </NameUser>
                <Box sx={{
                  display: "flex", flexDirection: "row", gap: 1, width: userRoles === "[ROLE_ADMIN]"
                    && request?.status === "EM_ATENDIMENTO" ? "400px" : "100%"
                }}>
                  <InfoBox>
                    <AvatarBox>
                      <IconLetter>
                        {" "}
                        {request?.nameExecutor
                          ? getFirstLetter(request?.nameExecutor)
                          : "--"}{" "}
                      </IconLetter>
                    </AvatarBox>
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: theme.COLORS.GRAY2,
                      }}
                    >
                      {request?.nameExecutor}
                    </Typography>
                  </InfoBox>
                  {userRoles === "[ROLE_ADMIN]" && request?.status === "EM_ATENDIMENTO" &&
                    <Tooltip title="Trocar responsável">
                      <IconButton size="small" sx={{
                        ":hover": { backgroundColor: theme.COLORS.DARKBLUE2 },
                        backgroundColor: theme.COLORS.DARKBLUE,
                        color: theme.COLORS.WHITE,
                      }}
                        onClick={() => handleAssignTask()}
                      >
                        <ChangeCircleIcon sx={{ color: theme.COLORS.WHITE }} />
                      </IconButton>
                    </Tooltip>}
                </Box>
              </ExecutorContainer>
            </ReletorAndExecutor>


            <InfoContainer $small={isSmallScreen}>
              <InfoRow>
                <InfoText>Status:</InfoText>
                <StyledStatus status={request?.status} />
              </InfoRow>
              <InfoRow>
                <InfoText>Prioridade:</InfoText>
                <StyledStatus status={request?.levelCriticality} />
              </InfoRow>
              <InfoRow>
                <InfoText>Data criação:</InfoText>
                <Typography>{formatDateBr(request?.createdAt)}</Typography>
              </InfoRow>
              <InfoRow>
                <InfoText>Data de atualização:</InfoText>
                <Typography>
                  {formatDateBr(request?.updatedAt) || "--"}
                </Typography>
              </InfoRow>
              <InfoRow>
                <InfoText>Projeto:</InfoText>
                <Typography>Adesão</Typography>
              </InfoRow>
            </InfoContainer>
            <History
              historic={historic}
              isSmallScreen={isSmallScreen}
            />
            {userRoles === "[ROLE_ADMIN]" ? (
              <Button
                sx={{ width: "100%", gap: 2, marginLeft: isSmallScreen ? "0px" : "3px", height: isSmallScreen ? "25px" : "37px" }}
                onClick={() => handleFinishTask()}
                size="small"
                disabled={isUpdating || request?.status !== "EM_ATENDIMENTO"}
              >
                <DoneIcon sx={{ color: theme.COLORS.WHITE_100, marginBottom: "4px" }} />
                <Typography
                  sx={{
                    color: theme.COLORS.WHITE_100,
                    fontWeight: "bold",
                    fontSize: "14px"
                  }}
                >
                  FINALIZAR CHAMADO
                </Typography>
              </Button>
            ) : (
              <Button
                sx={{ width: "100%", gap: 2, marginLeft: isSmallScreen ? "0px" : "3px", height: isSmallScreen ? "45px" : "37px" }}
                onClick={() => handleCloseTask()}
                size="small"
                disabled={isUpdating || request?.status !== "FINALIZADO"}
              >
                <DoneIcon sx={{ color: theme.COLORS.WHITE_100, marginBottom: "4px" }} />
                <Typography
                  sx={{
                    color: theme.COLORS.WHITE_100,
                    fontWeight: "bold",
                    fontSize: "14px"
                  }}
                >
                  FECHAR CHAMADO
                </Typography>
              </Button>
            )}
          </RightContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="image-modal-title"
            aria-describedby="image-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
            }}>
              {selectedImage && (
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={selectedImage}
                    alt="Expanded Product"
                    style={{
                      borderRadius: '8px',
                      transform: `rotate(${rotation}deg)`,
                      transition: 'transform 0.3s ease',
                      maxHeight: "90svh",
                      maxWidth: "90svw",
                    }}
                  />

                  {/* Botões sobrepostos na imagem */}
                  <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    gap: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '3px',
                    bottom: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}>
                    <Tooltip title="Girar para Esquerda">
                      <IconButton size="small" onClick={handleRotateLeft} sx={{ color: 'white' }}>
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Girar para Direita">
                      <IconButton size="small" onClick={handleRotateRight} sx={{ color: 'white' }}>
                        <RotateRightIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Restaurar">
                      <IconButton size="small" onClick={handleResetRotation} sx={{ color: 'white' }}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Baixar">
                      <IconButton size="small" onClick={() => downloadImage(selectedImage)} sx={{ color: 'white' }}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Fechar">
                      <IconButton size="small" onClick={handleClose} sx={{ color: theme.COLORS.WHITE, backgroundColor: theme.COLORS.RED, borderRadius: 0, padding: "10px" }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
        </>
      )
      }

    </Container >
  );
};

export default Request;
