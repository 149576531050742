import { Notification } from "app/components/toastNotification/toastNotification";

// Classe auxiliar para centralizar o tratamento de chamadas API
class ApiHelper {
  static async apiCall<T>(
    call: () => Promise<T>,
    successMessage?: string,
    showNotification: boolean = true
  ): Promise<T> {
    try {
      const response = await call();
      if (successMessage && showNotification) {
        Notification(successMessage, "success");
      }
      return response;
    } catch (err) {
      console.error(err);
      Notification(String(err), "error");
      throw err;
    }
  }
}

export default ApiHelper;
