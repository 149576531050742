import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { BACKEND_BASE_URL } from "../../utils/constants";
import { IResponseBody } from "../../models/utils";
import getMessageFromHttpStatus from "./axiosUtils";
import { Notification } from "app/components/toastNotification/toastNotification";
import useExpiredLoginHook from "core/hooks/expiredLogin/expiredLoginHook";

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(withAuth = true) {
    this.axiosInstance = axios.create({
      baseURL: BACKEND_BASE_URL,
    });

    if (withAuth) {
      this.setInterceptors();
    }
  }

  // Método para configurar interceptores
  private setInterceptors() {
    this.axiosInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem("userInfo");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error: any) => {
        this.handleResponseError(error);
        return Promise.reject(error);
      }
    );
  }

  // Método para tratar erros de resposta
  private handleResponseError(error: AxiosError) {
    if (error.code === "ERR_NETWORK" && error.config?.method === "get") {
      useExpiredLoginHook.getState().onOpen();
    }

    // pega a mensagem de erro ou o status ou erro interno na SPA
    const errorMessage =
      this.getErrorMessage(
        error.response as AxiosResponse<IResponseBody<any>, any>
      ) ||
      getMessageFromHttpStatus(error.response?.status) ||
      "Erro interno na SPA";

    Notification(errorMessage, "error");
  }

  // Método para extrair mensagens de erro da resposta
  private getErrorMessage(
    response: AxiosResponse<IResponseBody<any>, any> | undefined
  ): string | undefined {
    return response?.data?.errors?.join(", ");
  }

  // Função genérica para requisições GET
  public async get<T>(
    url: string,
    params?: any,
    successMessage?: string
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.get<T>(url, { params });
      this.handleSuccess(response, successMessage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Função genérica para requisições POST
  public async post<T>(
    url: string,
    data: any,
    successMessage?: string
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.post<T>(url, data);
      this.handleSuccess(response, successMessage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Função genérica para requisições PUT
  public async put<T>(
    url: string,
    data: any,
    successMessage?: string
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.put<T>(url, data);
      this.handleSuccess(response, successMessage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Função genérica para requisições DELETE
  public async delete<T>(url: string, successMessage?: string): Promise<T> {
    try {
      const response = await this.axiosInstance.delete<T>(url);
      this.handleSuccess(response, successMessage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Tratamento genérico para sucesso
  private handleSuccess<T>(
    response: AxiosResponse<T>,
    successMessage?: string
  ) {
    // Se a API envia a mensagem de sucesso no corpo da resposta
    const messageFromAPI =
      (response.data as any)?.message || (response.data as any)?.success;

    // Exibe a mensagem da API ou a mensagem passada como parâmetro
    const message = messageFromAPI || successMessage;

    if (message) {
      Notification(message, "success");
    }
  }
}

export const apiService = new ApiService();
export const noAuthApiService = new ApiService(false);
