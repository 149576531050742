import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";


export const PageContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${(props) => (props.$small ? "center" : "")};
  height: 100%;
  padding: ${(props) => (props.$small ? "0.5rem" : "2rem")};
  gap: 2.4rem;
`

export const TopContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$small ? "center" : "space-between")};
  align-items: center;
  flex-direction: ${(props) => (props.$small ? "column" : "row")};
  width: ${(props) => (props.$small ? "100%" : "100%")};
  height: ${(props) => (props.$small ? "40px" : "")};
`

export const ReletorContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: flex-start;
`

export const ReletorName = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  gap: 1rem;
  background: ${({ theme }) => theme.COLORS.GRAY7};
  padding: 0.3rem;
`

export const CircularIcon = styled(Box) <{ $small?: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${({ theme }) => theme.COLORS.GREEN2};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormInfos = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: ${(props) => (props.$small ? "100%" : "")};
`

export const Description = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  min-height: 200px;
  margin-bottom: 30px;
 `

export const Annex = styled(Box) <{ $small?: boolean }>`
width: 100%;
 `

export const DropAnnex = styled(Box) <{ $small?: boolean }>`
 border: 2px dashed;
 border-color: gray;
 width: ${props => props.$small ? "90%" : "60%"};
 height: auto;
 display: flex;
 flex-direction: column;
 gap: 1rem;
 justify-content: center;
 align-items: center;
 padding: 1rem;
 `

export const AnnexButton = styled(Button)`
 background-color: ${({ theme }) => theme.COLORS.GRAY4} !important;
 color: white;
 width: 80px;
 height: 30px;
 font-size: 0.7pc;
 font-weight: bold;
 border-radius: 3px;
 `

export const Images = styled(Box)`
display: flex;
flex-direction: row;
gap: 1rem;
max-width: 100%;
overflow-x: auto;
 `

export const AutoCompletes = styled(Box) <{ $small?: boolean }>`
 width: ${props => props.$small ? "90%" : "30%"};
 display: flex;
 flex-direction: column;
 gap: 1rem;
 justify-content: flex-start;
 align-items: center;
 margin-top: ${props => props.$small ? "20px" : "40px"};
 `

export const CreateCall = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  `

