import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import DefaultModal from "../modals/defaultModal/defaultModal"
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import theme from "core/theme/theme"
import GenericTextField from "../genericTextField/GenericTextField"
import { removeNonNumeric2 } from "core/utils/globalFunctions"
import CustomContentButton from "../Button/contentButton"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export interface ISelectItem {
    name: string
    value: any
    type?: 'texto' | 'date' | 'number' | 'status' | 'type' | 'genericStatus'
    options?: any[]
}

interface IFilterProps {
    title: string
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
    items: ISelectItem[]
    changePage?: React.Dispatch<React.SetStateAction<number>>
}

function NewDefaultFilter(props: IFilterProps) {
    const { onClose, items, isOpen, onOpen, title } = props

    const [searchParams, setSearchParams] = useSearchParams()

    const clearAllFilters = () => {
        const newParams = new URLSearchParams()
        items.forEach((item) => {
            newParams.delete(item.value)
        })
        setSearchParams(newParams)
        onCloseModal()
    }

    const initializeFilters = () => {
        const initialFilters: any = {}
        items.forEach((item) => {
            const value = searchParams.get(item.value)
            if (value && value !== '') {
                initialFilters[item.value] = value
            }
        })
        return initialFilters
    }

    // verifica se tem conteudo nas opcoes de filtro
    const hasOptions = useMemo(() => {}, [])

    // inicializa os valores dos filtros com o da url
    const [localFilterObject, setLocalFilterObject] =
        useState(initializeFilters)

    // estado inicial dos filtros
    const [initialFilters, setInitialFilters] = useState(initializeFilters)

    // Verifica se há filtros aplicados na URL
    const hasFilters = useMemo(() => {
        return items.some((item) => searchParams.has(item.value))
    }, [searchParams, items])

    // verifica se há mudanças nos filtros
    const hasChanges = useMemo(() => {
        return items.some((item) => {
            const urlValue = searchParams.get(item.value) || ''
            const localValue = localFilterObject[item.value] || ''
            return urlValue !== localValue
        })
    }, [searchParams, localFilterObject, items])

    // muda o respectivo valor do input de acordo com o objeto recebido
    const handleInputChange = (key: string, value: any) => {
        setLocalFilterObject((prevState: any) => ({
            ...prevState,
            [key]: value
        }))
    }

    // adiciona os valores do modal no URL
    const handleAddClick = () => {
        const newParams = new URLSearchParams()

        items.forEach((item) => {
            const value = localFilterObject[item.value]
            if (value) {
                newParams.set(item.value, value)
            }
        })

        setSearchParams(newParams)
        onCloseModal()
    }

    // fecha o modal e restaura os valores iniciais
    const onCloseModal = () => {
        setLocalFilterObject(initialFilters)
        onClose()
    }

    // atualiza constantemente os filtros do modal com os valores salvos no URL
    useEffect(() => {
        const updateFiltersFromURL = () => {
            const updatedFilters: any = {}
            items.forEach((item) => {
                const value = searchParams.get(item.value)
                if (value && value !== '') {
                    updatedFilters[item.value] = value
                }
            })
            setLocalFilterObject(updatedFilters)
            setInitialFilters(updatedFilters)
        }

        updateFiltersFromURL()
    }, [searchParams, items])

    // verifica se tem valor no filtro local
    const haveValue = useMemo(() => {
        return Object.entries(localFilterObject).some(
            ([_, value]) => value !== ''
        )
    }, [localFilterObject])

    const getColumnSize = (itemCount: number) => {
        if (itemCount <= 1) return 12
        if (itemCount === 2) return 6
        if (itemCount === 3) return 4
        return Math.max(3, Math.floor(12 / Math.min(itemCount, 4)))
    }

    const columnSize = getColumnSize(items.length)

    return (
        <DefaultModal
            isOpen={isOpen}
            onClose={onCloseModal}
            onOpen={onOpen}
            title={title}
        >
            <Box
                sx={{
                    minWidth: 300,
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                {items.map((filter) =>
                    filter.options ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center'
                            }}
                        >
                            <FormControl
                                variant="standard"
                                sx={{ width: '100%' }}
                            >
                                <InputLabel shrink={true}>
                                    {filter.name}
                                </InputLabel>
                                <Select
                                    label={filter.name}
                                    value={
                                        localFilterObject[filter.value] || ''
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            filter.value,
                                            event.target.value
                                        )
                                    }
                                    displayEmpty
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>Nenhum</em>
                                    </MenuItem>
                                    {filter.options.map((option) => (
                                        <MenuItem
                                            key={option.key}
                                            value={option.value}
                                        >
                                            {option.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Tooltip title="Limpar">
                                <IconButton
                                    disabled={
                                        localFilterObject[filter.value] ===
                                            '' ||
                                        localFilterObject[filter.value] ===
                                            undefined
                                    }
                                    sx={{ width: 35, height: 35, marginTop: 3 }}
                                    onClick={() => {
                                        setLocalFilterObject(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [filter.value]: ''
                                            })
                                        )
                                    }}
                                >
                                    <CloseIcon
                                        sx={{
                                            color:
                                                localFilterObject[
                                                    filter.value
                                                ] === '' ||
                                                localFilterObject[
                                                    filter.value
                                                ] === undefined
                                                    ? theme.COLORS.GRAY5
                                                    : theme.COLORS.RED
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center'
                            }}
                        >
                            <GenericTextField
                                label={filter.name}
                                style={{ width: '100%' }}
                                value={localFilterObject[filter.value] || ''}
                                name={filter.value}
                                props={{
                                    // type: filter.type,
                                    variant: 'standard'
                                }}
                                onChange={(event) =>
                                    handleInputChange(
                                        filter.value,
                                        filter.value === "cpforcnpj" ? removeNonNumeric2(event.target.value) : event.target.value
                                    )
                                }
                            />
                            <Tooltip title="Limpar">
                                <IconButton
                                    disabled={
                                        localFilterObject[filter.value] ===
                                            '' ||
                                        localFilterObject[filter.value] ===
                                            undefined
                                    }
                                    sx={{ width: 35, height: 35, marginTop: 3 }}
                                    onClick={() => {
                                        setLocalFilterObject(
                                            (prevState: any) => ({
                                                ...prevState,
                                                [filter.value]: ''
                                            })
                                        )
                                    }}
                                >
                                    <CloseIcon
                                        sx={{
                                            color:
                                                localFilterObject[
                                                    filter.value
                                                ] === '' ||
                                                localFilterObject[
                                                    filter.value
                                                ] === undefined
                                                    ? theme.COLORS.GRAY5
                                                    : theme.COLORS.RED
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 3,
                    gap: 1
                }}
            >
                <CustomContentButton
                    customColor={theme.COLORS.GREEN2}
                    IconComponent={AddCircleOutlineIcon}
                    label={'Filtrar'}
                    onClick={handleAddClick}
                    disabled={!hasChanges}
                />
                {/* <CustomContentButton
                    customColor={theme.COLORS.RED}
                    IconComponent={DeleteIcon}
                    label={'Resertar filtros'}
                    onClick={clearAllFilters}
                    disabled={!hasFilters}
                /> */}
            </Box>
        </DefaultModal>
    )
}

export default NewDefaultFilter