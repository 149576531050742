import { useAppSelector } from "core/hooks/reduxHooks";
import { Outlet, Navigate } from "react-router-dom";
const DefaultRoute = () => {
  const user = useAppSelector((state) => state.auth.userInfo);

  if (user?.login) {
    return <Navigate replace to={"/chamados "} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default DefaultRoute;
