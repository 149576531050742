// noinspection UnnecessaryLocalVariableJS
import { BACKEND_BASE_URL } from "./constants";
import { PDFDocument } from "pdf-lib";
import { TRole } from "./roles";

export function formatDocument(doc: string) {
  doc = doc.replace(/\D/g, "");
  if (doc.length === 11) {
    doc = doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (doc.length === 14) {
    doc = doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return doc;
}

export function capitalizeFirstLetter(input: string): string {
  if (!input) return input; // Verifica se a string não está vazia

  // Substitui todos os sublinhados por espaços
  const formattedInput = input.replace(/_/g, " ");

  // Capitaliza a primeira letra e transforma o restante em minúsculas
  return (
    formattedInput.charAt(0).toUpperCase() +
    formattedInput.slice(1).toLowerCase()
  );
}

export const downloadPdfFromBase64 = async (base64String: string) => {
  try {
    // Converte a string Base64 para um array de bytes (Uint8Array)
    const pdfBytes = Uint8Array.from(atob(base64String), (c) =>
      c.charCodeAt(0)
    );

    // Carrega o PDF com pdf-lib
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Salva o PDF como um blob
    const pdfData = await pdfDoc.save();
    const blob = new Blob([pdfData], { type: "application/pdf" });

    // Cria um link temporário para baixar o blob como PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "boleto.pdf";
    document.body.appendChild(link);
    link.click();

    // Remove o link temporário após o download
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Erro ao gerar o PDF:", error);
  }
};

export function formatCurrencyLimit(value: number): string {
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function upperCaseFirstLetter(str: string): string {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatPhoneNumber(phoneNumber: string) {
  let numericPhoneNumber = phoneNumber.replace(/[^0-9]/g, "");
  if (numericPhoneNumber.length === 11) {
    numericPhoneNumber = numericPhoneNumber.replace(
      /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
      (_, ddd, nine, firstPart, secondPart) =>
        `(${ddd}) ${nine}${firstPart}-${secondPart}`
    );
  } else if (numericPhoneNumber.length === 13) {
    numericPhoneNumber = numericPhoneNumber.replace(
      /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/,
      (_, country, ddd, nine, firstPart, secondPart) =>
        `+${country} (${ddd}) ${nine} ${firstPart}-${secondPart}`
    );
  }
  return numericPhoneNumber;
}

export function formatDate(date: Date) {
  const dia = String(date.getDate()).padStart(2, "0");
  const mes = String(date.getMonth() + 1).padStart(2, "0");
  const ano = date.getFullYear();
  return `${dia}/${mes}/${ano}`;
}

export function convertToBack(data: string): string {
  const dataSemHora = data.split(" ")[0];
  const [dia, mes, ano] = dataSemHora.split("/");
  const novaData = `${ano}-${mes}-${dia}`;
  return novaData;
}

export function formatCEP(cep: string): string {
  // Remove qualquer caractere não numérico do CEP
  const cepNumerico = cep.replace(/\D/g, "");
  const parte1 = cepNumerico.slice(0, 2);
  const parte2 = cepNumerico.slice(2, 5);
  const parte3 = cepNumerico.slice(5, 8);

  return `${parte1}.${parte2}-${parte3}`;
}
export function formatarNomeDaRota(): string {
  // Obtenha a URL atual
  const urlAtual = window.location.pathname;

  // Separe as partes da URL pelo caractere '/'
  const partes = urlAtual.split("/");

  // Verifique se a última parte é numérica
  const ultimaParte = partes[partes.length - 1];
  if (!isNaN(Number(ultimaParte))) {
    // Se for numérica, retorne a parte anterior
    return formatarNome(partes[partes.length - 2]);
  } else {
    // Caso contrário, retorne a própria última parte
    return formatarNome(ultimaParte);
  }
}

function formatarNome(nome: string): string {
  // Transforme a primeira letra em maiúscula
  const primeiraLetraMaiuscula = nome.charAt(0).toUpperCase();

  // Adicione espaços antes de letras maiúsculas
  const nomeFormatado =
    primeiraLetraMaiuscula + nome.slice(1).replace(/([A-Z])/g, " $1");

  return nomeFormatado;
}

export function formatDateBr(dataISO: string | undefined | null): string {
  if (!dataISO) {
    return "--";
  }

  const [ano, mes, dia] = dataISO.split("T")[0].split("-");

  return `${dia}/${mes}/${ano}`;
}

function convertTypeToString(type: string): string {
  switch (type) {
    case "SELLER": {
      return "seller";
    }
    case "CLIENT": {
      return "client";
    }
    case "ADMIN": {
      return "seller";
    }
    case "INDICATION": {
      return "indication";
    }
    default: {
      return "";
    }
  }
}

export function urlByUserType(type: string, id: number, put?: boolean): string {
  let userUrl = `${BACKEND_BASE_URL}v1/${convertTypeToString(type)}/${
    put ? "update/" : ""
  }${id}`;
  return userUrl;
}

export function validarCpfCnpj(input: string): boolean {
  const cleanedInput = input.replace(/\D/g, "");
  if (cleanedInput.length === 11) {
    return validarCpf(cleanedInput);
  } else if (cleanedInput.length === 14) {
    return validarCnpj(cleanedInput);
  }
  return false;
}

function validarCpf(cpf: string): boolean {
  const numeros = cpf.substring(0, 9).split("").map(Number);
  const digitos = cpf.substring(9).split("").map(Number);

  const calcularDigito = (numeros: number[]): number => {
    let soma = 0;
    let multiplicador = numeros.length + 1;

    for (const numero of numeros) {
      soma += numero * multiplicador;
      multiplicador--;
    }

    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  const primeiroDigito = calcularDigito(numeros);
  const segundoDigito = calcularDigito([...numeros, primeiroDigito]);

  return digitos[0] === primeiroDigito && digitos[1] === segundoDigito;
}

function validarCnpj(cnpj: string): boolean {
  const numeros = cnpj.substring(0, 12).split("").map(Number);
  const digitos = cnpj.substring(12).split("").map(Number);

  const calcularDigito = (numeros: number[], multiplicador: number): number => {
    let soma = 0;

    for (const numero of numeros) {
      soma += numero * multiplicador;
      multiplicador = multiplicador === 2 ? 9 : multiplicador - 1;
    }

    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  const primeiroDigito = calcularDigito(numeros, 5);
  const segundoDigito = calcularDigito([...numeros, primeiroDigito], 6);

  return digitos[0] === primeiroDigito && digitos[1] === segundoDigito;
}

export function formatCurrencyBR(input: number | undefined): string {
  if (input === undefined) {
    return "R$ 0,00";
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(input);
}

export function downloadCSVFromBase64(
  base64String: string,
  fileName: string
): void {
  // Convertendo a string base64 para um array de bytes
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Criando um Blob a partir do array de bytes
  const blob = new Blob([byteArray], { type: "text/csv" });

  // Criando um link temporário para download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  // Simulando o clique no link para iniciar o download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function removeExtraSpaces(input: string): string {
  return input.trim();
}

export function removeNonNumeric(
  input: string | undefined
): string | undefined {
  if (input) return input.replace(/\D/g, "");
  else return undefined;
}

export function formatCEPCollaborator(input: string): string {
  const cleaned = input.replace(/\D/g, "");
  if (cleaned.length !== 8) {
    return input;
  }
  return cleaned.replace(/(\d{5})(\d{3})/, "$1-$2");
}

export function removeNonNumeric2(input?: string): string {
  if (input) return input.replace(/\D/g, "");
  else return "";
}

export function formatCurrency(numero?: number): string {
  if (numero === undefined || numero === null) {
    return "0,00";
  }

  // Formatando o número com duas casas decimais
  const numeroFormatado = numero.toFixed(2);

  // Substituindo o ponto por vírgula
  return numeroFormatado.replace(".", ",");
}

export const formatStatus = (status: string): string => {
  if (status === "OPEN") return "Aberta";
  if (status === "CLOSED") return "Fechada";
  if (status === "PAID") return "Paga";
  if (status === "OVERDUE") return "Vencida";
  return status;
};

export const formatActivity = (activity: string): string => {
  if (activity === "active") return "Ativo";
  if (activity === "inactive") return "Inativo";
  return activity;
};

export function periodoAtual(tipo: "mes" | "ano"): string {
  const dataAtual = new Date();
  const mesAtual = dataAtual.toLocaleString("pt-BR", { month: "long" });
  const anoAtual = dataAtual.getFullYear();

  if (tipo === "mes") {
    return `Período: ${mesAtual} ${anoAtual}`;
  } else if (tipo === "ano") {
    return `Período: ${anoAtual}`;
  } else {
    throw new Error(
      'Tipo de período inválido. Por favor, passe "mes" ou "ano" como parâmetro.'
    );
  }
}

export function formatInt(value: string): string {
  let numericValue = value.replace(/\D/g, "");
  return numericValue;
}

export function downloadPdf(base64String: string, fileName: string) {
  // Decodifica a string base64 para um array de bytes
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Cria um blob a partir do array de bytes
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Cria um URL de objeto para o blob
  const url = window.URL.createObjectURL(blob);

  // Cria um link (a) e atribui o URL do blob
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Dispara o evento de clique no link para iniciar o download
  link.click();

  // Limpa o URL de objeto para liberar recursos
  window.URL.revokeObjectURL(url);
}

type Status = "WAITING" | "ANALYSIS" | "CONCLUDED" | "PENDING" | "PENDENTE";

export function translateStatus(status: string): string {
  switch (status) {
    case "WAITING":
      return "Aguardando aprovação";
    case "ANALYSIS":
      return "Em análise";
    case "CONCLUDED":
      return "Aprovado";
    case "PENDING":
    case "PENDENTE":
      return "Pendente";
    default:
      return "Status desconhecido";
  }
}

export function getFirstLetter(name: string): string {
  return name.charAt(0).toUpperCase();
}

export function formatDateWithTime(dateString: string) {
  return new Date(dateString).toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export const states = [
  { value: "AC", label: "AC" },
  { value: "AL", label: "AL" },
  { value: "AP", label: "AP" },
  { value: "AM", label: "AM" },
  { value: "BA", label: "BA" },
  { value: "CE", label: "CE" },
  { value: "DF", label: "DF" },
  { value: "ES", label: "ES" },
  { value: "GO", label: "GO" },
  { value: "MA", label: "MA" },
  { value: "MT", label: "MT" },
  { value: "MS", label: "MS" },
  { value: "MG", label: "MG" },
  { value: "PA", label: "PA" },
  { value: "PB", label: "PB" },
  { value: "PR", label: "PR" },
  { value: "PE", label: "PE" },
  { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" },
  { value: "RN", label: "RN" },
  { value: "RS", label: "RS" },
  { value: "RO", label: "RO" },
  { value: "RR", label: "RR" },
  { value: "SC", label: "SC" },
  { value: "SP", label: "SP" },
  { value: "SE", label: "SE" },
  { value: "TO", label: "TO" },
];
