import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import StyledStatus from "app/components/styledStatus/styledStatus";
import TaskService from "core/api/task/taskService";
import { TPaginatedTasks, TTaskPreview } from "core/models/task";
import theme from "core/theme/theme";
import { formatDateBr } from "core/utils/globalFunctions";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BackHandIcon from '@mui/icons-material/BackHand';
import CustomContentButton from "app/components/Button/contentButton";
import { useAppDispatch } from "core/hooks/reduxHooks";
import { fetchRequests } from "core/redux/slices/requestSlice";

export interface ICallsInfo {
  info: TTaskPreview;
  typeUser: string | undefined;
  filter: TPaginatedTasks | undefined;
}

const RequestTile = (props: Readonly<ICallsInfo>) => {
  const { info, typeUser, filter } = props;
  const dispatch = useAppDispatch();
  const user = localStorage.getItem("user");
  const userObj = JSON.parse(user!!);
  const userId = userObj.id;
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const handleAssignTask = useCallback(async () => {
    if (info.executor === null && typeUser === "[ROLE_ADMIN]" && info.id && userId) {
      await TaskService.assignTask(userId, info.id).then((resp) => {
        dispatch(fetchRequests(filter!));
      });
    }
  }, [info.executor, info.id, typeUser, userId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: isSmallScreen ? "97%" : "100%",
        minHeight: "60px",
        justifyContent: isSmallScreen ? "space-around" : "",
        borderRadius: "10px",
        background: theme.COLORS.GRAY7,
        border: `2px solid ${theme.COLORS.GRAY5}`,
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.COLORS.DARKBLUE2,
        },
      }}
      onClick={() => {
        navigate(`/chamado/${info.id}`);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            minWidth: "150px",
          }}
        >
          <Tooltip title={info.title}>
            <Typography sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: isSmallScreen ? "140px" : "140px",

            }}>{info.title}</Typography>
          </Tooltip>
          {/* <Typography>{`Usuário: ${info.requester.name}`}</Typography> */}
        </Box>
        <Typography sx={{
          width: "100px", fontWeight: "bold", marginLeft: isSmallScreen && typeUser !== "[ROLE_ADMIN]" ? "20px" : "0px",
        }}>
          {<StyledStatus status={info.levelCriticality!} />}
        </Typography>
        {!isSmallScreen &&
          <>
            <Typography sx={{ width: "100px", fontWeight: "bold" }}>
              {formatDateBr(info.updatedAt)}
            </Typography>
            <Typography sx={{ width: "100px", fontWeight: "bold" }}>
              {formatDateBr(info.createdAt)}
            </Typography>
          </>}
        <Typography
          sx={{
            fontWeight: "bold",
            width: 130,
            justifyContent: isSmallScreen? "center" : "",
            textAlign: isSmallScreen ? "center" : "",
            display: "flex",
          }}
        >
          {<StyledStatus status={info.status!} />}

        </Typography>
        {typeUser === "[ROLE_ADMIN]" &&
          (!isSmallScreen || info.status !== "EM_ATENDIMENTO") && (
            <Tooltip title={info.executor === null ? "Pegar" : ""}>
              <IconButton size="small" sx={{
                color: theme.COLORS.GRAY4,
                backgroundColor: info.executor === null ? theme.COLORS.DARKBLUE : theme.COLORS.GRAY5,
                ":hover": { backgroundColor: info.executor === null ? theme.COLORS.DARKBLUE2 : theme.COLORS.GRAY5 },
              }}
                onClick={(event) => {
                  event.stopPropagation(); // para que o onclick da box não seja disparado
                  handleAssignTask();
                }}>
                <BackHandIcon sx={{ color: theme.COLORS.WHITE }} />
              </IconButton>
            </Tooltip>
          )}
      </Box>
    </Box>
  );
};

export default RequestTile;
