import React from 'react';
import { Box, Typography } from '@mui/material';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { LaptopMac as LaptopMacIcon } from '@mui/icons-material';
import styled from 'styled-components';
import theme from 'core/theme/theme';
import { formatDateWithTime } from 'core/utils/globalFunctions';

interface HistoryItem {
    data_atualizacao_task: string;
    executor_name: string;
    descricao_task: string;
    requester_name: string;
}

interface HistoryProps {
    historic: HistoryItem[];
    isSmallScreen: boolean;
}

const History: React.FC<HistoryProps> = ({
    historic,
    isSmallScreen,
}) => {
    return (
        <HistoryContainer $small={isSmallScreen}>
            <Typography sx={{ fontWeight: "bold", color: theme.COLORS.GRAY2 }}>
                Histórico
            </Typography>

            {historic.length === 0 ? (
                <HistoryContent>
                    <Typography sx={{ fontWeight: "bold", color: theme.COLORS.GRAY2 }}>
                        Nenhuma alteração feita
                    </Typography>
                </HistoryContent>
            ) : (
                <HistoryContent>
                    <Timeline position="right" sx={{width: "100%"}}>
                        {historic.map((item, index) => (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent
                                    sx={{ m: "auto 0" }}
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {formatDateWithTime(item.data_atualizacao_task)}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color={"success"}>
                                        <LaptopMacIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{}}>
                                    <Typography sx={{ fontSize: 16, fontWeight: 600 }} component="span">
                                    {"Responsável: " + (item.descricao_task === "Status atualizado para fechado" ? item.requester_name : item.executor_name)}
                                    </Typography>
                                    <Typography>{item.descricao_task}</Typography>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </HistoryContent>
            )}
        </HistoryContainer>
    );
};

export default History;

// Estilos

const HistoryContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$small ? "98%" : "400px")};
`;

const HistoryContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;  
  border: 1px solid;
  border-radius: 3px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.3rem;
  gap: 3% !important;
  background: ${(props) => props.theme.COLORS.GRAY7};
`;
