import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import theme from "core/theme/theme";
import RequestTile from "./requestTile/requestTile";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Order } from "core/models/utils";
import DataTablePagination from "app/components/pagination/pagination";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  ButtonContainer,
  Circle,
  Content,
  Header,
  TableTitle,
  StatusContainer,
  StatusItem,
  TableContent,
  TableHeader,
  TableTitleContainer,
  Title,
  Priority,
  UpdatedAt,
  CreatedAt,
  Status,
  CircularProgressBox,
  OptionsContainer,
  Atribuir,
  Wellcome,
  PageContent,
} from "./styles";
import { TTaskStatus } from "core/models/taskStatus";
import { useAppDispatch, useAppSelector } from "core/hooks/reduxHooks";
import { fetchRequests } from "core/redux/slices/requestSlice";
import styled from "styled-components";
import NewDefaultFilter from "app/components/filter/newDefaultFilter";
import { TPaginatedTasks } from "core/models/task";
import { TCriticalityLevel } from "core/models/criticalityLevel";
import { userInfo } from "os";
import DefaultChipList from "app/components/chip/defaultChipList";

const Requests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { requests, loading } = useAppSelector((state) => state.request);
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const userRoles = basicUserInfo?.roles as any;
  const userId = basicUserInfo?.id;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchParams, setSearchParams] = useSearchParams();
  const [openfilter, setOpenFilter] = useState(false);
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const [filter, setFilter] = useState<TPaginatedTasks | undefined>();

  const requestFilter = userRoles === "[ROLE_ADMIN]" ? undefined : userId;

  const title = searchParams.get("title") ?? undefined;
  const levelCriticality = searchParams.get(
    "levelCriticality"
  ) as TCriticalityLevel | null;

  const navLinks = [
    { label: "Chamados", path: "/chamados" },
    { label: "Em andamento", path: "/chamados-em-andamento" },
    { label: "Concluídos", path: "/chamados-concluidos" },
  ];

  const items: any[] = [
    { name: "Título", value: "title", type: "texto" },
    {
      name: "Nível de criticidade",
      value: "levelCriticality",
      type: "genericStatus",
      options: [
        { key: "Baixa", value: "BAIXA" },
        { key: "Média", value: "MEDIA" },
        { key: "Alta", value: "ALTA" },
        { key: "Crítica", value: "CRITICA " },
      ],
    },
  ];

  useEffect(() => {
    let appliedStatus: TTaskStatus | null = null;

    // Define o filtro baseado no path da URL
    switch (location.pathname) {
      case "/chamados":
        appliedStatus = null;
        break;
      case "/chamados-em-andamento":
        appliedStatus = "EM_ATENDIMENTO";
        break;
      case "/chamados-concluidos":
        appliedStatus = "FECHADO";
        break;
      default:
        appliedStatus = null;
    }

    const filter: TPaginatedTasks = {
      title,
      status: appliedStatus,
      requesterId: requestFilter,
      levelCriticality,
      page,
      size: rowsPerPage,
      sort: orderBy + "," + order,

    };
    setFilter(filter);
    dispatch(fetchRequests(filter));
  }, [
    dispatch,
    title,
    location.pathname,
    page,
    rowsPerPage,
    orderBy,
    order,
    levelCriticality,
  ]);

  useEffect(() => {
    if (requests) {
      setCount(requests.totalElements);
    }
  }, [requests]);

  return (
    <PageContent $small={isSmallScreen}>
      <Header $small={isSmallScreen} sx={{justifyContent: userRoles !== "[ROLE_ADMIN]" ? "space-between" : "flex-end", 
      }}>
        {userRoles !== "[ROLE_ADMIN]" &&
          <ButtonContainer $small={isSmallScreen}>
            <Button
              onClick={() => navigate("/criarChamado")}
              sx={{ fontSize: 13, width: "70%" }}
            >
              NOVO CHAMADO
            </Button>
          </ButtonContainer>
        }
        <StatusContainer $small={isSmallScreen}>
          <StatusItem $small={isSmallScreen}>
            <Circle sx={{ background: theme.COLORS.YELLOW }} />
            <Typography>Aberto</Typography>
          </StatusItem>

          <StatusItem $small={isSmallScreen}>
            <Circle sx={{ background: theme.COLORS.BLUE3 }} />
            <Typography>Em atendimento</Typography>
          </StatusItem>
          <StatusItem $small={isSmallScreen}>
            <Circle sx={{ background: theme.COLORS.GREEN2 }} />
            <Typography>Finalizado por atendente</Typography>
          </StatusItem>
          <StatusItem $small={isSmallScreen}>
            <Circle sx={{ background: theme.COLORS.GRAY3 }} />
            <Typography>Fechado</Typography>
          </StatusItem>
        </StatusContainer>
      </Header>


      <Content $small={isSmallScreen}>
        <TableTitleContainer $small={isSmallScreen}>
          <TableTitle $small={isSmallScreen}>Histórico de chamadas</TableTitle>
          <ButtonsStack direction={"row"} spacing={2}>
            {navLinks.map((link) => {
              const isActive = location.pathname === link.path;

              return (
                <Button
                  key={link.label}
                  onClick={() => navigate(link.path)}
                  variant="text"
                  sx={{
                    color: isActive
                      ? theme.COLORS.WHITE
                      : theme.COLORS.DARKBLUE,
                    backgroundColor: isActive
                      ? theme.COLORS.DARKBLUE
                      : "transparent",
                    fontWeight: isActive ? "bold" : "normal",
                    "&:hover": {
                      backgroundColor: isActive
                        ? theme.COLORS.GRAY4
                        : theme.COLORS.GRAY4,
                    },
                    display: { xs: "block", md: "block" }
                  }}
                >
                  {link.label}
                </Button>
              );
            })}
          </ButtonsStack>
        </TableTitleContainer>

        <OptionsContainer $small={isSmallScreen}>
          <Tooltip title="Filtros">
            <IconButton
              sx={{ width: 30, height: 30 }}
              onClick={() => setOpenFilter(true)}
            >
              <FilterListIcon
                sx={{
                  fontSize: "20px",
                  color: theme.COLORS.DARKBLUE2,
                }}
              />
            </IconButton>
          </Tooltip>
          <DefaultChipList />
        </OptionsContainer>
        <Divider sx={{ marginX: "20px" }} />

        <TableHeader $small={isSmallScreen}>
          <Title $small={isSmallScreen}> Título </Title>
          <Priority $small={isSmallScreen}> Prioridade </Priority>
          {!isSmallScreen &&
            <>
              <UpdatedAt $small={isSmallScreen}> Atualizado em </UpdatedAt>
              <CreatedAt $small={isSmallScreen}> Criado em </CreatedAt>
            </>
          }
          <Status $small={isSmallScreen}> Status </Status>
          {userRoles === "[ROLE_ADMIN]" &&
            <Atribuir></Atribuir>}
        </TableHeader>

        <TableContent $small={isSmallScreen}>
          {loading ? (
            <CircularProgressBox>
              <CircularProgress />
            </CircularProgressBox>
          ) : (
            requests?.content?.map((info: any) => (
              <RequestTile info={info} typeUser={userRoles} filter={filter} />
            ))
          )}
        </TableContent>
      </Content>

      <DataTablePagination
        setPage={setPage}
        page={page}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        count={count}
      />

      <NewDefaultFilter
        isOpen={openfilter}
        items={items}
        onClose={() => setOpenFilter(false)}
        onOpen={() => setOpenFilter(true)}
        title="Filtrar chamados"
        changePage={setPage}
      />
    </PageContent>
  );
};

export default Requests;

const ButtonsStack = styled(Stack)``;
