import { TEdit, TVerificationToken } from "core/models/utils/user";
import { apiService } from "../axios/axiosInstance";
import { REQUESTER_SAVE, SEND_MESSEGE, VERIFICATION_TOKEN } from "core/utils/constants";
import { urlByUserType } from "core/utils/globalFunctions";
import ApiHelper from "../axios/apiHelper";

class UserService {
  static async updateUser(userNewInfos: TEdit): Promise<any> {
    return await ApiHelper.apiCall(
      () =>
        apiService.put(
          urlByUserType(userNewInfos.type, userNewInfos.id, true),
          userNewInfos
        ),
      "Usuário atualizado com sucesso"
    );
  }

  static async getUserName(
    userType?: string,
    id?: number | null
  ): Promise<any> {
    if (userType && id) {
      return await ApiHelper.apiCall(
        () => apiService.get(urlByUserType(userType, id)),
        "Nome do usuário carregado com sucesso"
      );
    }
    return "";
  }

  static async sendMessage(login: string): Promise<any> {
    return await ApiHelper.apiCall(
      () => apiService.post(SEND_MESSEGE, { login, type: "RESET_PASSWORD" }),
      "Mensagem enviada com sucesso"
    );
  }

  static async registerRequester(requester: any): Promise<any> {
    return await ApiHelper.apiCall(
      () => apiService.post(REQUESTER_SAVE, requester),
      "Usuário cadastrado com sucesso"
    );
  }


  static async verificationToken(token: TVerificationToken): Promise<any> {
    return await ApiHelper.apiCall(
      () =>
        apiService.post(VERIFICATION_TOKEN, {
          ...token,
          type: "RESET_PASSWORD",
        }),
      "Token verificado com sucesso"
    );
  }
}

export default UserService;
