import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./views/login/login";
import DefaultRoute from "core/utils/defaultRoute";
import ForgotPassword from "./views/login/forgotPassword/ForgotPassword";
import RedefinePassword from "./views/login/redefinePassword/redefinePassword";
import ProtectedRoute from "core/utils/protectedRoute";
import Requests from "./views/requests/requests";
import CallRequester from "./views/newRequest/newRequest";
import Request from "./views/request/request";
import RegisterRequester from "./views/registerRequester/registerRequester";

function App() {
  return (
    <Routes>
      <Route element={<DefaultRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="/esqueceuSenha" element={<ForgotPassword />} />
      <Route path="/recuperacaoSenha" element={<RedefinePassword />} />
      <Route path="/cadastrar-usuario" element={<RegisterRequester />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/criarChamado" element={<CallRequester />} />

        <Route path="/chamados" element={<Requests />} />
        <Route path="/chamados-em-andamento" element={<Requests />} />
        <Route path="/chamados-concluidos" element={<Requests />} />


        <Route path="/chamado/:id" element={<Request />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
