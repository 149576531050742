import React from "react";
import {
  InputProps,
  TextField,
  TextFieldProps,
  FormHelperText,
  InputBaseComponentProps,
} from "@mui/material";
import { formatDocument, formatPhoneNumber } from "core/utils/globalFunctions";

type TDefaultTextField<T> = {
  name: string;
  label: string;
  value: T;
  props?: InputProps & TextFieldProps;
  small?: boolean;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  style?: React.CSSProperties | undefined;
};

const documentFieldConfig: Record<
  string,
  { maxLength?: number; format?: (value: string) => string }
> = {
  login: { maxLength: 18, format: formatDocument },
  cpf: { maxLength: 18, format: formatDocument },
  telephone: { maxLength: 12, format: formatPhoneNumber },
  "address.zipCod": { maxLength: 8 },
  "contacts.telephone": { maxLength: 12, format: formatPhoneNumber },
  "contacts.cellPhone2": { maxLength: 12, format: formatPhoneNumber },
  "contacts.cellPhone1": { maxLength: 12, format: formatPhoneNumber },
};

function GenericTextField<T>({
  name,
  label,
  value,
  props,
  helperText,
  error,
  type,
  onBlur,
  onChange,
  style,
}: TDefaultTextField<T>) {

  const config = documentFieldConfig[name] || {
    maxLength: undefined,
    format: undefined,
  };
  
  const formattedValue = config.format ? config.format(value as string) : value;

  return (
    <TextField
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      size="small"
      variant="outlined"
      autoComplete="off"
      margin="none"
      id={name}
      label={label}
      value={formattedValue}
      error={error}
      style={style}
      inputProps={{
        maxLength: config.maxLength,
      }}
      sx={{
        margin: error ? 0 : 0.5,
      }}
      helperText={
        error && (
          <FormHelperText sx={{ margin: 0, padding: 0 }} error={error}>
            {String(helperText)}
          </FormHelperText>
        )
      }
      {...props}
    />
  );
}

export default GenericTextField;
