import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, InputAdornment, Chip, Input, Avatar } from '@mui/material';
import { PeopleAlt as PeopleAltIcon, Person as PersonIcon, Navigation as NavigationIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import styled from 'styled-components';
import theme from 'core/theme/theme';

interface Comment {
  requesterName: string | null;
  executorName: string | null;
  createdAt: string;
  comente: string;
  image?: string;
}

interface ChatCommentsProps {
  comments: Comment[];
  comment: string;
  postingComment: boolean;
  userRoles: string;
  isSmallScreen: boolean;
  setComment: (value: string) => void;
  handlePostComment: (comment: string, image?: string) => void;
  formatDateWithTime: (date: string) => string;
}

const DefaultChat: React.FC<ChatCommentsProps> = ({
  comments,
  comment,
  postingComment,
  userRoles,
  isSmallScreen,
  setComment,
  handlePostComment,
  formatDateWithTime,
}) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]); // Para armazenar várias imagens
  const [selectedImageNames, setSelectedImageNames] = useState<string[]>([]); // Nomes dos arquivos selecionados

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages: string[] = [];
      const newImageNames: string[] = [];

      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImages.push(reader.result as string); // Converte a imagem para base64
          newImageNames.push(file.name); // Armazena o nome do arquivo
          // Atualiza os estados após ler todos os arquivos
          if (newImages.length === files.length) {
            setSelectedImages((prevImages) => [...prevImages, ...newImages]);
            setSelectedImageNames((prevNames) => [...prevNames, ...newImageNames]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleDeleteImage = (index: number) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setSelectedImageNames((prevNames) => prevNames.filter((_, i) => i !== index));
  };


  return (
    <ChatContainer $small={isSmallScreen}>
      <CommentQuantity>
        <PeopleAltIcon sx={{ color: theme.COLORS.GRAY2 }} />
        <Quantity>
          {comments.length} Comentários nesse chamado
        </Quantity>
      </CommentQuantity>

      <CommentContainer $small={isSmallScreen}>
        {comments
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
          .map((comment, index) => (
            <CommentContent key={index}>
              <Box sx={{ display: "flex", height: "100%" }}>
                <PersonIconStyled $isRequester={comment.requesterName !== null} />
              </Box>
              <CommentBox>
                <UserBox>
                  <NameUserChat $small={isSmallScreen}>
                    {comment.requesterName || comment.executorName}
                  </NameUserChat>
                  <DataComment $small={isSmallScreen}>{formatDateWithTime(comment.createdAt)}</DataComment>
                </UserBox>
                <CommentText>{comment.comente}</CommentText>
              </CommentBox>
            </CommentContent>
          ))}
      </CommentContainer>

      <InputContainer>
        <IconContainer>
          <PersonIcon
            sx={{
              borderRadius: "50%",
              width: 32,
              height: 32,
              color: theme.COLORS.WHITE,
              background: userRoles !== "[ROLE_ADMIN]" ? theme.COLORS.DARKBLUE2 : theme.COLORS.GREEN1,
            }}
          />
        </IconContainer>

        <TextField
          label="Adicionar comentário"
          name="comentario"
          multiline
          style={{ width: "100%" }}
          value={comment}
          minRows={1}
          maxRows={3}
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && comment !== "") {
              e.preventDefault(); // Impede a quebra de linha no TextField
              handlePostComment(comment)
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{
                  display: 'flex', flexDirection: 'column', gap: '4px',
                  maxHeight: '50px',
                  overflowY: 'auto',
                }}>
                    {selectedImages.map((image, index) => (
                    <Chip
                      key={index}
                      label={selectedImageNames[index]}
                      onDelete={() => handleDeleteImage(index)}
                      avatar={<Avatar alt={selectedImageNames[index]} src={image} />}
                      sx={{ maxWidth: 120 }}
                      size="small"
                    />
                  ))}
                </Box>
                <label htmlFor="image-upload">
                  <input
                    type="file"
                    id="image-upload"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                  <IconButton component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
        />

        <IconButton
          sx={{ color: comment === "" || postingComment ? theme.COLORS.DARKBLUE2 : theme.COLORS.DARKBLUE2 }}
          onClick={() => handlePostComment(comment)}
          disabled={comment === "" || postingComment}
        >
          <NavigationIcon sx={{ transform: "rotate(90deg)", width: 40, height: 40 }} />
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default DefaultChat;

// Estilos 

const ChatContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2%;
  width: 100%;
  height: ${(props) => (props.$small ? "200px" : "400px")};
  gap: 3%;
`;

const CommentQuantity = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 2%;
`;

const Quantity = styled(Typography) <{ $small?: boolean }>`
  font-size: 18px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

const CommentContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: ${(props) => (props.$small ? "200px" : "400px")};
  overflow-y: auto;
  width: 90%;
`;

const CommentContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 1%;
  align-items: center;
`;

const PersonIconStyled = styled(PersonIcon) <{ $isRequester: boolean }>`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: ${theme.COLORS.WHITE};
  background: ${(props) =>
    props.$isRequester ? theme.COLORS.DARKBLUE2 : theme.COLORS.GREEN1};
`;

const CommentText = styled(Typography) <{ $small?: boolean }>`
  font-size: 16px !important;
  color: ${theme.COLORS.GRAY2};
`;

const CommentBox = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 1%;
`;

const UserBox = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 1%;
`;

const NameUserChat = styled(Typography) <{ $small?: boolean }>`
  font-size: ${(props) => (props.$small ? "12px" : "16px")} !important;
  font-weight: bold !important;
  color: ${theme.COLORS.GRAY2};
`;

const DataComment = styled(Typography) <{ $small?: boolean }>`
  color: ${theme.COLORS.GRAY2};
  font-size: ${(props) => (props.$small ? "12px" : "16px")} !important;
`;

const InputContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 1%;
  align-items: center;
  width: 100%;
`;

const IconContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
