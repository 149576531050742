import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "core/hooks/reduxHooks";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { logout } from "core/redux/slices/authSlice";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import useExpiredLoginHook from "core/hooks/expiredLogin/expiredLoginHook";
import Footer from "app/components/footer/Footer";
import theme from "core/theme/theme";
import Logo from "images/assets/bitPequenoSvg.svg";
import PageBackground from "./pageBackGround";
import ContentContainer from "./contentContainer";

const Content = styled(Box)`
  flex: 1;
  overflow: auto;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = useExpiredLoginHook();

  //teste
  if (localStorage.getItem("userInfo") === null) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <>
      <PageBackground>
        <Outlet />
      </PageBackground>

      <DefaultDialog
        title="Sessão expirada"
        isOpen={isOpen}
        confirmAction={() => dispatch(logout())}
        onCloseAction={onClose}
        body={<Typography textAlign={"center"}>deslogar?</Typography>}
      />
    </>
  );
};

export default ProtectedRoute;
