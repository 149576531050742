import { Box, Typography } from "@mui/material";
import theme from "core/theme/theme";
import styled from "styled-components";

export const PageContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

export const Header = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const ButtonContainer = styled(Box) <{ $small: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$small ? "center" : "flex-start"};
  align-items: center;
  width: ${(props) => props.$small ? "300px" : "35%"};
  padding: 0;
  margin: 0;
`;

export const StatusContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column; 
  width: ${(props) => props.$small ? "100%" : "20%"}; 
`;

export const StatusItem = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;

export const Circle = styled(Box) <{ $small?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const Content = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 10;
  margin-top: 10px;
`;

export const OptionsContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2%;
  align-items: center;
  margin: ${(props) => props.$small ? "5px 0px 0px -1px" : "20px"};
`;

export const TableTitleContainer = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.$small ? "column" : "row"}; 
  gap: ${(props) => props.$small ? "0.5rem" : "2rem"};
  align-items: ${(props) => props.$small ? "" : "center"};
`;

export const SearchContainer = styled(Box) <{ $small?: boolean }>`
  padding-right: 1rem;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const TableHeader = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around !important;
  width: 100%;
  margin-top: 10px;
`;

export const TableContent = styled(Box) <{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${(props) => props.$small ? "550px" : "700px"};
  justify-content: flex-start;
  align-items: stretch; 
  padding: ${(props) => props.$small ? "0" : "1rem"};
  gap: 1.4rem;
`;

export const TableTitle = styled(Typography) <{ $small?: boolean }>`
  font-size: 30px !important;
  font-weight: bolder !important;
  color: ${theme.COLORS.GRAY2};
  padding-left: ${(props) => props.$small ? "0" : "1rem"} !important;
`;

export const Wellcome = styled(Typography) <{ $small?: boolean }>`
  font-size: 30px !important;
  width: 100%;
  font-weight: bolder !important;
  color: ${theme.COLORS.GRAY2};
  text-align: flex-start !important;
  margin-left: 57px !important;
`;

export const Title = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
  width: ${(props) => props.$small ? "150px" : "130px"} !important;
  text-align: center !important;
  color: gray;
`;

export const Priority = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
   width: ${(props) => props.$small ? "110px" : "110px"} !important;
   margin-left: ${(props) => props.$small ? "20px" : "30px"} !important;
  color: gray;
`;

export const UpdatedAt = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
  width: 110px !important;
  color: gray;
`;

export const CreatedAt = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
  width: 110px;
  color: gray;
`;

export const Status = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
  width: ${(props) => props.$small ? "130px" : "110px"} !important;
  margin-right: ${(props) => props.$small ? "0px" : "20px"} !important;
  margin-left: ${(props) => props.$small ? "30px" : "0px"} !important;
  color: gray;
`;

export const Atribuir = styled(Typography) <{ $small?: boolean }>`
  font-weight: bold !important;
  text-align: left !important;
  width: 50px !important;
  color: gray;
`;

export const CircularProgressBox = styled(Box) <{ $small?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;
