
import * as yup from "yup";
import { validarCpfCnpj } from "./globalFunctions";
const loginSchema = yup.object({
  login: yup
    .string()
    .required("Digite o usuário"),
  // .test("cpf-cnpj-validation", "Documento inválido", validarCpfCnpj),
  password: yup
    .string()
    .min(5, "Senha deve ter ao menos 5 digitos")
    .required("Digite a senha"),
});

const forgotPasswordSchema = yup.object({
  login: yup
    .string()
    .required("Digite o usuário")
    .test("cpf-cnpj-validation", "Documento inválido", validarCpfCnpj),
});

const redefinePassword = yup.object({
  password: yup
    .string()
    .required("Senha é obrigatória")
    .min(6, "A senha deve ter no mínimo 6 caracteres"),
  confirmPassword: yup
    .string()
    .required("Confirmação de senha é obrigatória")
    .oneOf([yup.ref("password")], "As senhas devem ser iguais"),
});

const registerTaskSchema = yup.object().shape({
  description: yup.string()
    .required("Descrição é obrigatória")
    .min(10, "Descrição deve ter no mínimo 10 caracteres"),
  requesterId: yup.number()
    .required("ID do solicitante é obrigatório"),
  projectId: yup.number()
    .min(1, "Selecione um projeto"),
  levelCriticality: yup.string()
    .required("Selecione um nível de criticidade"),
  title: yup.string()
    .required("Título é obrigatório")
    .min(5, "Título deve ter no mínimo 5 caracteres"),
  dataSaveImage: yup.array()
    .min(1, "Pelo menos uma imagem é obrigatória")
    .required("Pelo menos uma imagem é obrigatória"),
});


const registerRequesterSchema = yup.object({
  cpf: yup.string().required("CPF/CNPJ é obrigatório").test("cpf-cnpj-validation", "Documento inválido", validarCpfCnpj),
  name: yup.string().required("Nome é obrigatório"),
  password: yup.string()
    .min(6, "A senha deve ter pelo menos 6 caracteres")
    .required("Senha é obrigatória"),
  confirmPassword: yup.string()
    .oneOf([yup.ref("password")], "As senhas não conferem")
    .required("Campo obrigatório"),
  projectId: yup.array()
    .of(yup.number().required())
    .min(1, "Selecione pelo menos um projeto"),
  contacts: yup.object({
    telephone: yup.string()
      .required("Telefone é obrigatório"),
    cellPhone1: yup.string()
      .required("Celular principal é obrigatório"),
    email: yup.string()
      .email("Email de contato inválido")
      .required("Email de contato é obrigatório"),
  }),
});

export const Validations = {
  loginSchema,
  forgotPasswordSchema,
  redefinePassword,
  registerTaskSchema,
  registerRequesterSchema
};
