import { apiService } from "../axios/axiosInstance";
import { COMMENT_EXECUTOR, COMMENT_REQUESTER, GET_COMMENTS } from "core/utils/constants";
import ApiHelper from "../axios/apiHelper";
import { IResponseBody } from "core/models/utils";

class CommentService {
  static async postRequesterComment(comment: any): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.post(COMMENT_REQUESTER, comment),
      "Comentário salvo com sucesso",
      false
    );
  }

  static async postExecutorComment(comment: any): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.post(COMMENT_EXECUTOR, comment),
      "Comentário salvo com sucesso",
      false
    );
  }

  static async getComments(taskId: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get(`${GET_COMMENTS}${taskId}/comments`),
      "Comentários recuperados com sucesso",
      false
    );
  }
}

export default CommentService;
