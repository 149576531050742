import {
  Button,
  Stack,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomContentButton from "app/components/Button/contentButton";
import { useAppDispatch, useAppSelector } from "core/hooks/reduxHooks";
import { logout } from "core/redux/slices/authSlice";
import Logo from "images/assets/bitPequenoSvg.svg";
import styled from "styled-components";
import theme from "core/theme/theme";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TRole, verifyRole } from "core/utils/roles";

const UserNavbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navLinks = [
    { label: "Chamados", path: "/chamados" },
  ];

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const hanbleBackPage = () => {
    if (location.pathname !== "/chamados") {
      navigate(-1);
    }
  };

  return (
    <Navbar>
      <LeftSide>
        <img src={Logo} alt="Logo" />
        <Button
          onClick={hanbleBackPage}
          sx={{
            width: "100px",
            height: "40px",
            backgroundColor: theme.COLORS.DARKBLUE,
            color: theme.COLORS.WHITE,
            borderRadius: "10px",
            boxShadow: "none",
          }}
        >
          <KeyboardBackspaceIcon sx={{ color: theme.COLORS.WHITE }} />
          <Typography sx={{ color: theme.COLORS.WHITE, marginLeft: "5px" }}>
            Voltar
          </Typography>
        </Button>
      </LeftSide>

      <MenuContainer>
        {/* Botão de hamburguer */}
        <IconButton
          onClick={() => toggleDrawer(true)}
          sx={{
            display: { xs: "block", md: "none" },
            color: theme.COLORS.WHITE,
          }}
        >
          <MenuIcon />
        </IconButton>

        {/* Menu para telas maiores */}
        <ButtonsStack direction={"row"} spacing={2}>
          {navLinks.map((link) => {
            const isActive = location.pathname === link.path;

            return (
              <Button
                key={link.label}
                onClick={() => navigate(link.path)}
                variant="text"
                sx={{
                  color: isActive ? theme.COLORS.DARKBLUE : theme.COLORS.WHITE,
                  backgroundColor: isActive
                    ? theme.COLORS.WHITE
                    : "transparent",
                  fontWeight: isActive ? "bold" : "normal",
                  "&:hover": {
                    backgroundColor: isActive
                      ? theme.COLORS.WHITE
                      : theme.COLORS.GRAY4,
                  },
                  display: { xs: "none", md: "block" }, // Esconde em telas pequenas
                }}
              >
                {link.label}
              </Button>
            );
          })}
        </ButtonsStack>

        <CustomContentButton
          label="Sair"
          onClick={() => dispatch(logout())}
          IconComponent={LogoutIcon}
          sx={{ display: { xs: "none", md: "block" } }} // Esconde o botão em telas pequenas
        />
      </MenuContainer>

      {/* Drawer para o menu mobile */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <List>
          {navLinks.map((link) => (
            <ListItem
              key={link.label}
              onClick={() => {
                navigate(link.path);
                toggleDrawer(false);
              }}
            >
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Navbar>
  );
};

export default UserNavbar;

const ButtonsStack = styled(Stack)``;

const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-bottom: 150px;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.COLORS.DARKBLUE};
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
`;
