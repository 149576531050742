import { TPaginatedFilterTasks } from "core/models/task";
import { IResponseBody, IPage } from "core/models/utils";
import {
  ASSIGN_TASK,
  CLOSE_TASK,
  DELETE_TASK,
  FINISH_TASK,
  GET_HISTORY,
  GET_IMAGE,
  GET_TASK_BY_ID,
  GET_TASKS,
  SAVE_TASK,
} from "core/utils/constants";
import { apiService } from "../axios/axiosInstance";
import ApiHelper from "../axios/apiHelper";

class TaskService {
  static async getTasks(
    filter: TPaginatedFilterTasks
  ): Promise<IResponseBody<IPage<any>>> {
    return await ApiHelper.apiCall(
      () =>
        apiService.get<IResponseBody<IPage<any>>>(GET_TASKS, {
          status: filter.status,
          requesterId: filter.requesterId,
          executorId: filter.executorId,
          projectId: filter.projectId,
          levelCriticality: filter.levelCriticality,
          title: filter.title,
          page: filter.page,
          size: filter.size,
        }),
      "Tarefas carregadas com sucesso",
      false
    );
  }

  static async getTaskById(id: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get<IResponseBody<any>>(`${GET_TASK_BY_ID}${id}`),
      "Tarefa carregada com sucesso",
      false
    );
  }
  
  static async getHistory(id: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get<IResponseBody<any>>(`${GET_HISTORY}${id}`),
      "Histórico carregado com sucesso",
      false
    );
  }
  
  static async saveTask(task: any): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.post(SAVE_TASK, task),
      "Tarefa salva com sucesso",
      true
    );
  }

  static async assignTask(executorId: number, taskId: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.post(ASSIGN_TASK, {
        executorId,
        taskId
      }),
      "Tarefa atribuída com sucesso",
      true
    );
  }

  static async closeTask(taskId: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get(`${CLOSE_TASK}${taskId}`),
      "Tarefa fechada com sucesso",
      true
    );
  }

  static async finishTask(taskId: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get(`${FINISH_TASK}${taskId}`),
      "Tarefa finalizada com sucesso",
      true
    );
  }

  static async getImage(taskId: number): Promise<IResponseBody<any>> {
    return await ApiHelper.apiCall(
      () => apiService.get(`${GET_IMAGE}${taskId}`),
      "Imagem carregada com sucesso",
      false
    );
  }

  static async deleteTask(id: number): Promise<void> {
    return await ApiHelper.apiCall(
      () => apiService.delete(`${DELETE_TASK}${id}`),
      "Tarefa excluída com sucesso",
      true
    );
  }
}

export default TaskService;
