import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import theme from "core/theme/theme";
import Logo from "images/assets/bitPequenoSvg.svg";
import { logout } from "core/redux/slices/authSlice";
import CustomContentButton from "app/components/Button/contentButton";
import { useAppDispatch } from "core/hooks/reduxHooks";
import styled from "styled-components";
import Footer from "app/components/footer/Footer";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
import UserNavbar from "app/components/navbar/navbar";
interface Props {
  children: React.ReactNode;
}

const PageBackground = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();


  return (
    // layout de todas as páginas
    <>
      {/* nav */}
      <UserNavbar />

      {/* mensagem de boas vindas */}
      <Message $small={isSmallScreen}>
        <Typography sx={{ color: theme.COLORS.WHITE, fontSize: 23 }}>
          Olá <span style={{ fontWeight: "bold" }}>Matheus</span>, essa
        </Typography>
        <Typography
          sx={{
            color: theme.COLORS.WHITE,
            fontSize: 23,
            marginTop: "-10px",
            textAlign: "center",
          }}
        >
          é nossa plataforma de abertura de chamados
        </Typography>
      </Message>

      {/* fundo por trás do contéudo */}
      <Background />

      {/* contéudo das páginas */}
      <PageContent $small={isSmallScreen}>{props.children}</PageContent>

      {/* footer */}
      <Footer />
    </>
  );
};

export default PageBackground;

const PageContent = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${theme.COLORS.WHITE};
  position: absolute;
  top: 680px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1000px;
  width: ${(props) => (props.$small ? "90svw" : "1100px")};
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${theme.COLORS.GRAY5};
`;

const Message = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${(props) => (props.$small ? "90svw" : "")};
  top: ${(props) => (props.$small ? "130px" : "100px")};
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Background = styled.div`
  background-color: ${theme.COLORS.GRAY6};
  height: 1000px;
`;