// * Api

export const BACKEND_BASE_URL = "https://api.chamados.bitbeelabs.tech/";

export const BACKEND_POSICARD_URL = "https://api.cartoes.posicard.com.br/";

// * User
export const AUTH = `${BACKEND_BASE_URL}v1/auth/login`;
export const CANCEL_ACCOUNT = `${BACKEND_BASE_URL}v1/request/send-request`;

// forgot password
export const SEND_MESSEGE = `${BACKEND_BASE_URL + "v1/message/enviar-message"}`;
export const VERIFICATION_TOKEN = `${
  BACKEND_BASE_URL + "v1/message/verification-token"
}`;

// tasks
export const SAVE_TASK = `${BACKEND_BASE_URL + "v1/task/save"}`;
export const GET_TASKS = `${BACKEND_BASE_URL + "v1/task"}`;
export const GET_TASK_BY_ID = `${BACKEND_BASE_URL + "v1/task/"}`;
export const DELETE_TASK = `${BACKEND_BASE_URL + "v1/task/delete/"}`;
export const ASSIGN_TASK = `${BACKEND_BASE_URL + "v1/task/assignexecutor"}`;
export const CLOSE_TASK = `${BACKEND_BASE_URL + "v1/task/close/"}`;
export const FINISH_TASK = `${BACKEND_BASE_URL + "v1/task/finish/"}`;
export const GET_IMAGE = `${BACKEND_BASE_URL + "v1/image/"}`;
export const GET_HISTORY = `${BACKEND_BASE_URL + "v1/task/history/"}`;


// requester
export const REQUESTER_SAVE = `${BACKEND_BASE_URL + "v1/requester/save"}`;

// Comentários dos posts
export const COMMENT_EXECUTOR = `${BACKEND_BASE_URL + "v1/comment/savexecutor"}`;
export const COMMENT_REQUESTER = `${BACKEND_BASE_URL + "v1/comment/saverequester"}`;
export const GET_COMMENTS = `${BACKEND_BASE_URL + "v1/task/"}`;


// * Project
export const GET_PROJECTS = `${BACKEND_BASE_URL + "v1/project"}`;