import { Container, Box } from "@mui/material";
import styled from "styled-components";
import Logo from "images/assets/bitsvg.svg";

export const RegisterContainer = styled(Container) <{ $small?: boolean }>`
  height: ${(props) => (props.$small ? "100vh" : "70%")};
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: ${(props) => (props.$small ? "2em" : "1rem")};
  border: 2px solid ${({ theme }) => theme.COLORS.GRAY5};
`;

export const RegisterContentBox = styled.form<{ $small?: boolean }>`
  width: ${(props) => (props.$small ? "100%" : "100%")};
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${(props) => (props.$small ? "3%" : "5%")};
`;


export const UserInfos = styled(Box) <{ $small?: boolean }>`
  width: ${(props) => (props.$small ? "100%" : "100%")};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${(props) => (props.$small ? "0%" : "0 1%")};
  gap: 1rem;
  `

export const ContactInfos = styled(Box) <{ $small?: boolean }>`
  width: ${(props) => (props.$small ? "100%" : "100%")};
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${(props) => (props.$small ? "0%" : "0 1%")};
  gap: 1rem;
  `