import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TaskService from "core/api/task/taskService";
import { TPaginatedTasks, TTaskPreview } from "core/models/task";
import { IPage } from "core/models/utils";

type RequestState = {
  requests: IPage<TTaskPreview>;
  loading: boolean;
  error: string | null;
};

const initialState: RequestState = {
  requests: {
    content: [],
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    totalElements: 0,
    totalPages: 0,
    size: 0,
  },
  loading: false,
  error: null,
};

// Action assíncrona para buscar os dados da API
export const fetchRequests = createAsyncThunk(
  "request/fetchRequests",
  async (params: TPaginatedTasks, thunkAPI) => {
    try {
      const response = await TaskService.getTasks(params);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setRequests: (state, action: PayloadAction<IPage<TTaskPreview>>) => {
      state.requests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchRequests.fulfilled,
        (state, action: PayloadAction<IPage<TTaskPreview>>) => {
          state.loading = false;
          state.requests = action.payload;
        }
      )
      .addCase(fetchRequests.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setRequests } = requestSlice.actions;
export default requestSlice.reducer;
